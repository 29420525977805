import palette from './palette';


const typography = theme => ({
  fontFamily: 'Montserrat SemiBold, Arial',
  h1: {
    color: palette.text.primary,
    fontFamily: 'Montserrat BlackItalic',
    fontWeight: 500,
    letterSpacing: '-0.24px',
    fontSize: "60px",
  },
  h2: {
    color: palette.primary.main,
    fontFamily: 'Montserrat Bold',
    fontWeight: 500,
    letterSpacing: '-0.24px',
    fontSize: "46px",
  },
  h3: {
    color: palette.text.primary,
    fontFamily: 'Montserrat SemiBold',
    fontWeight: 500,
    letterSpacing: '-0.05px',
    fontSize: "26px",
    lineHeight: 1.3
  },
  h4: {
    fontSize: "14px",
    color: palette.text.secondary,
    fontFamily: 'Montserrat Medium',
    fontWeight: 500,
    letterSpacing: '-0.06px',
  },
  h5: {
    fontSize: "12px",
    color: palette.text.secondary,
    fontFamily: 'Montserrat Medium',
    fontWeight: 500,
    letterSpacing: '-0.05px',
  },
  h6: {
    color: palette.text.primary,
    fontFamily: 'Montserrat Bold',
    fontWeight: 500,
    fontSize: '26px',
    letterSpacing: '-0.05px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontFamily: 'Montserrat SemiBoldItalic',
    letterSpacing: '-0.05px',
    fontSize: '22px',
    lineHeight: 1.45
  },
  subtitle2: {
    fontSize: "20px",
    color: palette.text.primary,
    fontFamily: 'Montserrat Semibold',
    fontWeight: 400,
    letterSpacing: '-0.05px',
    lineHeight: 1.2
  },
  body1: {
    color: palette.text.secondary,
    fontFamily: 'Montserrat Medium',
    fontWeight: 500,
    letterSpacing: '-0.06px',
    fontSize: "18px"
  },
  body2: {
    color: palette.text.secondary,
    fontFamily: 'Montserrat Medium',
    fontSize: '16px',
    letterSpacing: '-0.04px',
    lineHeight: 1.7
  },
  button: {
    color: palette.text.primary,
    fontSize: '14px'
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '8px',
    fontFamily: 'Montserrat Medium',
    letterSpacing: '0.33px',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    textTransform: 'uppercase'
  },
});

export default typography;