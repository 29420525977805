import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ButtonColor from '../../components/ButtonColor';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        borderTop: '1px solid #dedede',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center'
    },
    content: {

    },
    container: {
        maxWidth: '95%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '1112px'
        },
    },
    sendEmailButtonBox: {
        width: '100%',
        marginTop: '14px',
        [theme.breakpoints.up('md')]: {
            width: '172px',
            marginBottom: '10px',
            marginTop: 0
        },
        [theme.breakpoints.only('sm')]: {
            marginTop: 0,
            width: '120px',
        }
    },
    sendEmailButton: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.white,
        height: '58px',
        fontSize: "16px",
        lineHeight: "14px",
        borderRadius: 30,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            height: '53.63px',
        }
    },
    newsLetter: {
        fontFamily: 'Montserrat Bold',
        fontWeight: 500,
        letterSpacing: '-0.24px',
        [theme.breakpoints.up('lg')]: {
            fontSize: "22.8px",
            lineHeight: "49.02px",
        },
        [theme.breakpoints.only('md')]: {
            fontSize: "20.8px",
            lineHeight: "31.06px",
            letterSpacing: '-0.06px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: "18.8px",
            lineHeight: "29.06px",
            letterSpacing: '-0.06px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "27.2px",
            lineHeight: "29.24px",

        }
    },
    newsLetterContainer: {
        [theme.breakpoints.only('xs')]: {
            display: 'flex',
            paddingTop: '34px',
            flexWrap: 'wrap',
            width: '100%',
        },
    },
    newsLetterDescription: {
        lineHeight: '26px',
        marginTop: '18px',
        marginBottom: '56px',
        [theme.breakpoints.up('lg')]: {
            fontSize: "20.52px",
            lineHeight: "25.08px",
        },
        [theme.breakpoints.only('md')]: {
            fontSize: "17px",
            lineHeight: "23px",
            letterSpacing: '-0.06px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: '-0.06px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: "12.24px",
            lineHeight: "18.36px",
        },
        [theme.breakpoints.only('xs')]: {
            marginBottom: '0',
        },

    },
    firstName: {
        width: '100%',
    },
    emailAddress: {
        width: '100%',
        marginTop: '14px',
        [theme.breakpoints.up('sm')]: {
            marginTop: 0
        }
    },
    newsLetterGrid: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '92px',
            paddingBottom: '98px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '58px',
            paddingBottom: '58px',
        }
    }

}));


const Newsletter = ({ }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState({
        values: {
            firstName: '',
            emailAddress: '',
        },
    });

    useEffect(() => {

        setFormState(prevFormState => ({
            ...prevFormState
        }));


    }, [formState.values]);

    const handleChangeEmail = event => {
        const value = event.target.value;

        setFormState(prevFormState => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                emailAddress: value
            }
        }));
    };

    const handleChangeFirstName = event => {
        const value = event.target.value;

        setFormState(prevFormState => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                firstName: value
            }
        }));
    }

    const handleSubmit = async event => {
        // event.preventDefault();

        // try {
        //     setLoading(true);

        //     var newsLetterSubscribe = await NewsletterService.newsletterSubscribe(formState.values);
        //     if (newsLetterSubscribe.status == 200) {
        //         setLoading(false);
        //     }

        // } catch (err) {
        //     setLoading(false);
        //     console.log(err);
        // }
    }

    return (
        <div className={classes.root}>
            <form noValidate autoComplete="off" style={{ width: '100%' }}>
                <Container className={classes.container}>

                    <Typography component="div" className={classes.content}>

                        <Grid container spacing={2} className={classes.newsLetterGrid}>
                            {/* Impressione, mas não sobrecarregue. */}
                            <Grid item sm={1} />
                            <Grid item sm={10}>
                                <Typography
                                    component="h3"
                                    variant="h3"
                                    color="primary"
                                    className={classes.newsLetter}
                                >
                                    Newsletter
                            </Typography>

                                <Typography
                                    variant="body1"
                                    className={classes.newsLetterDescription}
                                >
                                    Fique por dentro de todas as novidades e promoções da nossa empresa.
                        </Typography>
                            </Grid>
                            <Grid item sm={1} />


                            <Grid item sm={1} />

                            <Grid item sm={4} xs={12} className={classes.newsLetterContainer}>
                                <TextField
                                    label="Nome"
                                    name="firstName"
                                    variant="outlined"
                                    className={classes.firstName}
                                    fullWidth
                                    value={
                                        formState.values.firstName ?
                                            formState.values.firstName :
                                            ''
                                    }
                                    onChange={handleChangeFirstName}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    label="E-mail"
                                    name="emailAddress"
                                    variant="outlined"
                                    fullWidth
                                    className={classes.emailAddress}
                                    onChange={handleChangeEmail}
                                    value={
                                        formState.values.emailAddress ?
                                            formState.values.emailAddress :
                                            ''
                                    }

                                />
                            </Grid>
                            <Grid item sm={2} xs={12}>
                                <Box className={classes.sendEmailButtonBox}>
                                    <ButtonColor
                                        className={classes.sendEmailButton}
                                        loading={loading}
                                        disabled={loading}
                                        green
                                        onClick={handleSubmit}
                                    >
                                        Enviar
                                        </ButtonColor>
                                </Box>
                            </Grid>

                            <Grid item md={1} />

                        </Grid>

                    </Typography>

                </Container>
            </form>
        </div>
    );
}

export default Newsletter;