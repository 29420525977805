/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import MasterWithNavLayout from './layouts/MasterWithNav';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />
  },
  {
    route: '*',
    component: MasterWithNavLayout,
    routes: [
      {
        path: '/home',
        exact: true,
        component: lazy(() => import('./views/Home'))
      },
      {
        path: '/privacy-policy',
        exact: true,
        component: lazy(() => import('./views/PrivacyPolicy'))
      },
      {
        path: '/promo',
        exact: true,
        component: lazy(() => import('./views/Promo'))
      },
      {
        path: '/form-promo',
        exact: true,
        component: lazy(() => import('./views/FormPromo'))
      },
      {
        path: '/form-login',
        exact: true,
        component: lazy(() => import('./views/FormLogin'))
      }
    ]
  }
];

export default routes;
