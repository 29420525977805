import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, useTheme, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: '#663aca',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function ButtonColor({
  children,
  blue,
  green,
  grey,
  loading,
  purple,
  red,
  ...rest
}) {
  const values = { ...rest };
  const theme = useTheme();
  const classes = useStyles();

  let buttonStyle = {};

  if (values.disabled) {
    buttonStyle = {
      color: theme.palette.common.white,
      backgroundColor: '#aeadad'
    };
  } else if (blue) {
    buttonStyle = {
      color: theme.palette.common.white,
      backgroundColor: '#2197c2',
      '&:hover': {
        backgroundColor: '#1d7899'
      }
    };
  } else if (green) {
    buttonStyle = {
      color: theme.palette.common.white,
      backgroundColor: '#b6d178',
      '&:hover': {
        backgroundColor: '#9ab166'
      }
    };
  } else if (grey) {
    buttonStyle = {
      color: theme.palette.common.white,
      backgroundColor: '#cccccc',
      '&:hover': {
        backgroundColor: '#aeadad'
      }
    };
  } else if (purple) {
    buttonStyle = {
      color: theme.palette.common.white,
      backgroundColor: '#663aca',
      '&:hover': {
        backgroundColor: '#5932b0'
      }
    };
  } else if (red) {
    buttonStyle = {
      color: theme.palette.common.white,
      backgroundColor: '#ff2d64',
      '&:hover': {
        backgroundColor: '#e92e5f'
      }
    };
  }

  return (
    <div className={classes.wrapper}>
      <Button {...rest} style={buttonStyle}>
        {children}
      </Button>
      {loading && (
        <CircularProgress className={classes.buttonProgress} size={24} />
      )}
    </div>
  );
}

ButtonColor.propTypes = {
  blue: PropTypes.bool,
  children: PropTypes.node,
  green: PropTypes.bool,
  grey: PropTypes.bool,
  loading: PropTypes.bool,
  purple: PropTypes.bool,
  red: PropTypes.bool
};

export default ButtonColor;
