import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, ButtonBase, Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import useRouter from '../../utils/useRouter';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '25vh',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#161a1e'
  },
  container: {
    display: 'flex',
    maxWidth: '95%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1100px'
    }
  },
  gridContainer: {
    flexGrow: 1
  }
}));

const FooterBar = ({ }) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <div className={classes.root}>
      <div
        className={classes.container}
        style={{
          paddingTop: '10vh',
          paddingBottom: '10vh',
          width: '100%'
        }}>
        <Grid
          alignContent="center"
          className={classes.gridContainer}
          container
          spacing={4}>
          <Grid item sm={5} xs={12}>
            <img
              alt="Logo"
              src="/images/futstat-logo.svg"
              style={{
                width: '80px',
                height: '100px'
              }}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <Typography style={{ color: '#eeeeee' }} variant="body2">
              Benefícios
            </Typography>
            <Typography style={{ color: '#eeeeee' }} variant="body2">
              Comparativo
            </Typography>
            <Typography style={{ color: '#eeeeee' }} variant="body2">
              Funcionalidades
            </Typography>
            <Typography style={{ color: '#eeeeee' }} variant="body2">
              Quem somos
            </Typography>
          </Grid>

          <Grid item sm={3} xs={12}>
            <Typography style={{ color: '#eeeeee' }} variant="body2">
              {`Copyright © ${new Date().getFullYear()} Futstat`}
            </Typography>
            <Typography style={{ color: '#eeeeee' }} variant="body2">
              Todos os direitos reservados
            </Typography>
            <Typography style={{ color: '#eeeeee' }} variant="body2">
              <a href="mailto:futstat@futstat.com.br" style="color: #fffff">
                futstat@futstat.com.br
              </a>
            </Typography>
            <Link
              href="mailto:futstat@futstat.com.br"
              variant="body2"
              style={{ color: '#ffffff' }}>
              futstat@futstat.com.br
            </Link>
            <Link
              component="button"
              onClick={() => {
                router.history.push('/privacy-policy');
              }}
              style={{ color: '#eeeeee' }}
              variant="body2">
              Política de privacidade
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default FooterBar;
