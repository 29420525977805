import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, ButtonBase } from '@material-ui/core';
import useRouter from '../../utils/useRouter';

const useStyles = makeStyles(theme => ({
    button: {
        "&&:hover": {
            backgroundColor: "transparent !important"
        }
    },
    appBar: {
        backgroundColor: theme.palette.background.default,
        borderBottom: '1px solid #e1e1e1',
        height: '75px',
        [theme.breakpoints.down('xs')]: {
            height: '47px',
        }
    },
    toolbar: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        maxWidth: '95%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '1112px'
        },
        marginLeft: '0',
        marginRight: '0'
    },
    login: {
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: 'Montserrat Medium',
        fontWeight: 500,
    },
    content: {
        width: '100%'
    },
    signup: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        maxHeight: '25px',
        maxWidth: '100px',
        fontSize: "12px",
        lineHeight: "16px",
        fontFamily: 'Montserrat Medium',
        fontWeight: 500,
        letterSpacing: '-0.05px',
        '&&:hover': {
            backgroundColor: '#ff2d64',
        },
        '&&:focus': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "14px",
            lineHeight: "24px",
            maxHeight: '28px',
            maxWidth: '120px',
        },
        [theme.breakpoints.only('md')]: {
            marginRight: '20px'
        },
        [theme.breakpoints.only('sm')]: {
            marginRight: '20px'
        }
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial',
        [theme.breakpoints.only('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.only('sm')]: {
            marginLeft: '20px'
        }
    },
    mokshaGrid: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'center',
        alignItems: 'center',
    },
    moksha: {
        width: '81px',
        [theme.breakpoints.up('sm')]: {
            width: '124px',
        }
    },
    rightElements: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'center',
        alignItems: 'center'
    }

}));

const TopBar = ({ }) => {
    const classes = useStyles();
    const router = useRouter();

    return (
        <AppBar color="default" elevation={0} className={classes.appBar} position="fixed">
            <Toolbar className={classes.toolbar} disableGutters>
                <Container className={classes.container}>

                    <Grid container spacing={0}>
                        <Grid item xs={2} className={classes.mokshaGrid}>
                            <ButtonBase className={classes.cardAction} onClick={() => { router.history.push('/') }} disableRipple disableTouchRipple>
                                <img
                                    alt="Logo"
                                    src="/images/moksha-header-small.png"
                                    className={classes.moksha}
                                />
                            </ButtonBase>
                        </Grid>
                        <Grid item xs={10} className={classes.rightElements}>

                            <Button href="https://moksha.azurewebsites.net"
                                className={classes.login}
                                disableRipple
                            >
                                entrar
                                </Button>

                            <Button
                                onClick={() => { router.history.push('/signup') }}
                                className={classes.signup}
                                disableRipple
                            >
                                Inscreva-se
                            </Button>
                        </Grid>
                    </Grid>

                </Container>

            </Toolbar>

        </AppBar >

    );
}

export default TopBar;